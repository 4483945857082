'use strict';

const WORDS:Set<string> = new Set<string>();

export function addWord(word:string):void {
    WORDS.add(word.trim().toLowerCase());
}

export function parseHasIn(input:string|undefined):string[] {
    if (input == undefined) {
        return ["", "", "", "", ""];
    }

    let pieces:string[] = input.split(",");
    if (pieces.length != 5) {
        throw Error(`Input value for hasIn parameter must have 5 strings separated by commas.`);
    }
    for (let index:number = 0;index < 5;index++) {
        // todo: filter to [a-z]
        pieces[index] = pieces[index].toLowerCase().trim();
    }
    return pieces;
}

export function parseAbsent(input:string|undefined):string {
    if (input == undefined) {
        return "";
    }
    return input.toLowerCase();
}

export function parseHasAt(input:string|undefined):string {
    if (input == undefined) {
        return "?????";
    }
    if (input.length != 5) {
        throw Error(`Exact match string must be length 5 with ? at unknonwn places.`);
    }
    return input;
}

// ['f',
// undefined,
// undefined,
// 'n',
// 'y'
// ]
export function findPossibleWords(
    knownLetters: string,
    possibleLetters: string[],
    excludedLetters: string,
    wordList?:string[]|undefined
    ): string[] {
    if (!wordList) {
        wordList = Array.from(WORDS.values());
    }
    let possible:string[] = wordList.filter((w) => {
        // 1. First exclude if known any must-match letter doesn't match
        for (let i = 0;i < 5;i++) {
            let knownLetter = knownLetters.charAt(i);
            let currentChar = w.charAt(i);
            if ("?" == knownLetter) {
                continue;
            } else if (knownLetter != currentChar) {
                return false;
            }
        }

        // 2. Exclude any word that contains any letter in our excluded letters list.
        for (let i = 0;i < excludedLetters.length;i++) {
            let l:string = excludedLetters.charAt(i);
            if (w.indexOf(l) > -1) {
                return false;
            }
        };
        
        for (let index = 0;index < 5;index++) {
            let letterList = possibleLetters[index];
            for (let i = 0;i < letterList.length;i++) {
                let currentLetter:string = letterList.charAt(i);
                if (currentLetter == w.charAt(index)) {
                    return false;
                } else if (w.indexOf(currentLetter) <= -1) {
                    return false;
                }
            }
        };
        return true;
    });
    return possible.sort();
}


